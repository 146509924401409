<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.863"
    height="20.707"
    viewBox="0 0 21.863 20.707"
    :class="type"
  >
    <g transform="translate(1.431 1.495)">
      <path
        class="a"
        d="M-75.669,0l-2.375,6.694-7.125.181,5.657,4.319L-81.54,18l5.871-4.025L-69.8,18l-2.028-6.806,5.657-4.319-7.125-.181Z"
        transform="translate(85.169)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Star",
  props: ["type"],
};
</script>

<style lang="scss" scoped>
.filled {
  transition: all ease 0.3s;
  .a {
    fill: #ffb400;
    stroke: #ffb400;
  }
}
.hollow {
  transition: all ease 0.3s;
  .a {
    transition: all ease 0.3s;
    fill: transparent;
    stroke: #2d323c;
  }

  &:hover {
    .a {
      fill: #ffb400;
      stroke: #ffb400;
    }
  }
}
</style>