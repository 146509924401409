var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"favourites-container"},[(_vm.userFavourites.length == 0)?_c('div',{staticClass:"no-favourites"},[_c('Star',{attrs:{"type":"hollow"}}),_c('h3',[_vm._v("You haven't starred any events yet")]),_c('p',{staticClass:"large sub-title"},[_vm._v(" Keep an eye on events that interest you by starring them. ")]),_c('router-link',{attrs:{"to":"/events"}},[_c('p',{staticClass:"large"},[_vm._v("Find events to star")])])],1):_vm._e(),_c('div',{staticClass:"favourites-event-container"},_vm._l((_vm.userFavourites),function(favourite){return _c('div',{key:favourite.id,staticClass:"favourite-event-card"},[_c('div',{staticClass:"favourite-card-header"},[_c('h4',[_vm._v(_vm._s(favourite.event.name))]),_c('span',{staticClass:"star",on:{"click":function($event){return _vm.deleteFavourite(favourite.id)}}},[_c('Star',{attrs:{"type":"filled"}})],1)]),_c('div',{staticClass:"sub-title"},[_c('p',[_c('b',[_vm._v(_vm._s(_vm.eventDate(favourite.event.startDate)))])])]),_c('div',{staticClass:"registration-status"},[(
            favourite.event.status == 'COMPLETED' ||
            _vm.isDateYesterday(favourite.event.endDate)
          )?_c('div',{staticClass:"event-finished"},[_c('span',{staticClass:"status-label strom"},[_vm._v("Finished")])]):(
            _vm.showEventIsLive(favourite.event.startDate, favourite.event.status)
          )?_c('div',{staticClass:"event-ongoing"},[_c('span',{staticClass:"status-label grass"},[_vm._v("In progress")])]):(
            favourite.event.isRegistrationOpen == false &&
            (favourite.event.registrationOpenAt == null ||
              _vm.isDateInThePast(favourite.event.registrationOpenAt))
          )?_c('div',{staticClass:"registration-closed"},[_c('Off'),_c('p',[_vm._v("Registration closed")])],1):(
            favourite.event.isRegistrationOpen == false &&
            _vm.isDateInThePast(favourite.event.registrationOpenAt) == false
          )?_c('div',{staticClass:"registration-closed"},[_c('RegistrationOpen',{attrs:{"color":"sunrise"}}),_c('p',[_vm._v(" Registration opens "+_vm._s(_vm.dateformatter(favourite.event.registrationOpenAt))+" ")])],1):(favourite.event.isRegistrationOpen)?_c('div',{staticClass:"registration-open"},[_c('RegistrationOpen',{attrs:{"color":""}}),_c('p',[_vm._v("Registration open")])],1):_vm._e()]),_c('div',{staticClass:"event-button"},[_c('router-link',{attrs:{"to":{
            name: 'public-event-results',
            params: { eventId: favourite.event.id },
          }}},[_c('button',{staticClass:"to-event"},[_c('p',{staticClass:"large"},[_c('b',[_vm._v("View event")])])])])],1)])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }