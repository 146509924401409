<template>
  <div class="favourites-container">
    <div class="no-favourites" v-if="userFavourites.length == 0">
      <Star type="hollow" />
      <h3>You haven't starred any events yet</h3>

      <p class="large sub-title">
        Keep an eye on events that interest you by starring them.
      </p>
      <router-link to="/events"
        ><p class="large">Find events to star</p></router-link
      >
    </div>

    <div class="favourites-event-container">
      <div
        class="favourite-event-card"
        v-for="favourite in userFavourites"
        :key="favourite.id"
      >
        <div class="favourite-card-header">
          <h4>{{ favourite.event.name }}</h4>
          <span class="star" @click="deleteFavourite(favourite.id)">
            <Star type="filled" />
          </span>
        </div>
        <div class="sub-title">
          <p>
            <b>{{ eventDate(favourite.event.startDate) }}</b>
          </p>
        </div>
        <div class="registration-status">
          <div
            class="event-finished"
            v-if="
              favourite.event.status == 'COMPLETED' ||
              isDateYesterday(favourite.event.endDate)
            "
          >
            <span class="status-label strom">Finished</span>
          </div>
          <div
            class="event-ongoing"
            v-else-if="
              showEventIsLive(favourite.event.startDate, favourite.event.status)
            "
          >
            <span class="status-label grass">In progress</span>
          </div>

          <div
            class="registration-closed"
            v-else-if="
              favourite.event.isRegistrationOpen == false &&
              (favourite.event.registrationOpenAt == null ||
                isDateInThePast(favourite.event.registrationOpenAt))
            "
          >
            <Off />
            <p>Registration closed</p>
          </div>
          <div
            class="registration-closed"
            v-else-if="
              favourite.event.isRegistrationOpen == false &&
              isDateInThePast(favourite.event.registrationOpenAt) == false
            "
          >
            <RegistrationOpen color="sunrise" />
            <p>
              Registration opens
              {{ dateformatter(favourite.event.registrationOpenAt) }}
            </p>
          </div>
          <div
            class="registration-open"
            v-else-if="favourite.event.isRegistrationOpen"
          >
            <RegistrationOpen color="" />
            <p>Registration open</p>
          </div>
        </div>
        <div class="event-button">
          <router-link
            :to="{
              name: 'public-event-results',
              params: { eventId: favourite.event.id },
            }"
          >
            <button class="to-event">
              <p class="large"><b>View event</b></p>
            </button></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { orderBy } from "lodash";
import moment from "moment";
import Star from "@/assets/icons/Star";
import Off from "@/assets/icons/Off";
import RegistrationOpen from "@/assets/icons/RegistrationOpen";
export default {
  name: "DashboardFavourites",
  components: {
    Star,
    Off,
    RegistrationOpen,
  },
  data() {
    return {
      userFavourites: [],
    };
  },
  computed: {},
  methods: {
    async deleteFavourite(id) {
      await this.$axios({
        headers: {
          Authorization: this.$store.state.user.sessionInfo.token,
        },
        data: {
          query: `
                mutation {
                  DeleteFavouriteEvent(favouriteId: "${id}")
                }
              `,
        },
      });
      this.$store.dispatch("showMessage", {
        show: true,
        message: "Poof! Event unstarred.",
        type: "warning",
      });
      this.loadData();
    },
    showEventIsLive(date, eventStatus) {
      if (
        eventStatus == "ONGOING" &&
        moment(date).isBefore(moment().add(30, "minutes"))
      ) {
        return true;
      } else {
        return false;
      }
    },
    isDateYesterday(date) {
      return moment(date).isBefore(moment().startOf("day"));
    },
    isDateInThePast(date) {
      return moment(date).isBefore();
    },
    eventDate(date) {
      return moment(date).format("ddd D MMM [at] HH:mm");
    },
    async loadData() {
      try {
        const userFavourites = await this.$axios({
          headers: {
            Authorization: this.$store.state.user.sessionInfo.token,
          },
          data: {
            query: `
                {
                  me{
                    favouriteEvents{
                      id
                      event{
                        id
                        name
                        startDate
                        endDate
                        isRegistrationOpen
                      }
                    }
                  }
                }
              `,
          },
        });

        this.userFavourites = orderBy(
          userFavourites.data.data.me.favouriteEvents,
          ["event.startDate"],
          ["asc"]
        );
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "Error",
        });
      }
    },
  },
  beforeMount() {
    this.loadData();
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/Typography";

.favourites-event-container {
  display: flex;
  flex-direction: column;
  padding: 0 15px;

  .favourite-event-card {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 10px;
    border: 1px solid $sleet;
    padding: 20px 22px 26px 22px;
  }

  .favourite-card-header {
    position: relative;
    padding-right: 48px;
    h4 {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .star {
      cursor: pointer;
      position: absolute;
      top: 1px;
      right: 0;
    }
  }

  .sub-title {
    margin-top: 10px;
  }
  .registration-status {
    margin-top: 24px;
    padding-top: 18px;
    border-top: 1px solid $fog;
    display: flex;
    align-items: center;
    > div {
      display: flex;
    }
    svg {
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }
  }

  .to-event {
    margin-top: 18px;
    border: none;
    width: 100%;
    appearance: none;
    background: $cloud;
    transition: all ease 0.3s;
    height: 48px;
    border-radius: 6px;
    &:hover {
      cursor: pointer;
      background: $mist;
    }
  }
}
.no-favourites {
  padding: 0 24px;
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    height: 24px;
    width: auto;
  }
  a {
    margin-top: 32px;
    color: $ocean;
  }
  h3 {
    text-align: center;
    margin-top: 18px;
  }
  .sub-title {
    color: $strom;
    text-align: center;
    margin-top: 24px;
  }
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
  .no-favourites {
    svg {
      height: 38px;
      width: auto;
    }
    h3 {
      margin-top: 24px;
    }
  }
  .favourites-event-container {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;

    .favourite-event-card {
      width: 30%;
      margin-right: 4.995%;
      margin-top: 48px;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
</style>
